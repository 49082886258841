import React from 'react'
import Layout from '../components/layout'

import { events } from '../conf/events'

const renderEventsPosts = () => {
    return events.map((post, index) => {
        return (
            <div className="post row" key={"post_" + index}>
                <div className="col-12">
                    <h3>{post.title}</h3>
                    <h5>{post.date} {post.timeStart}-{post.timeEnd}, {post.ort}</h5>
                    <p>
                        {post.text1}
                        {post.text2 ? <><br /> {post.text2}</> : ''}
                    </p>
                    <p>{post.preis}</p>
                    {post.link ? <a href={post.link} target="_blank" className="button-link" rel="noopener noreferrer">{post.linkName}</a> : ''}
                    <span style={{ marginLeft: '16px' }}>{post.linkDesc}</span>
                </div>
                {/*post.img ? <div className="col-2"><img src={post.img} width="150px" /></div> : ''*/}
            </div>
        )
    })
}

const EventsForm = () => (
    <Layout>
        <section id="Events" className="section-wrapper">
            <header className="section-header">
                <h3>Events</h3>
            </header>
            {renderEventsPosts()}
        </section>
    </Layout>
)

export default EventsForm
