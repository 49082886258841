import abacus2020 from '../images/events/HR Abacus.png'

export const events = [
    {
        title: 'Abacus HR 2020 – So gehts',
        text1: 'An diesem Webinar erfahren alles Wissenswerte zu den neuen HR Funktionen in Abacus ERP 2020. Folgende Themen werden dabei behandelt:',
        text2: 'MyAbacus HR Portal, Zeiterfassung, Mitarbeiterbeurteilung, HR Dashboards, Weitere HR Prozesse, Spesenerfassung, Arbeitszeugniserstellung, Payroll u.v.m.',
        preis: 'Teilnahme: kostenlos',
        date: '06.05.2020',
        timeStart: '10:00',
        timeEnd: '12:00',
        ort: 'via Microsoft Teams Live Event',
        link: 'mailto:info@lepa.ch?subject=Anmeldung%20Webinar%20Abacus%20HR%202020&body=Hiermit%20m%C3%B6chte%20ich%20mich%20gerne%20an%20das%20Webinar%20vom%2006.05.2020%20von%2010-12%20Uhr%20anmelden.%20Den%20Link%20f%C3%BCr%20die%20Teilnahme%20bekomme%20ich%20kurz%20vor%20der%20Veranstaltung.%0A%0AName%2C%20Vorname%3A%0AFirma%3A%20%0AFunktion%3A',
        linkName: 'Anmeldung',
        linkDesc: 'Melden Sie sich via Mail an. Anschliessend schicken wir Ihnen den Teilnahmelink',
        img: abacus2020
    },
]